import {Link} from 'gatsby';
import React, {FunctionComponent} from 'react';

import {LocaleSwitch} from './locale_switch';

interface OwnProps {
    locale?: 'nl' | 'en';
    translatedPagePath?: string;
}

export const OverlayNav: FunctionComponent<OwnProps> = ({locale, translatedPagePath}) => {
    return (
        <nav role="navigation" className="overlay__nav">
            <Link
                to={locale === 'en' ? '/en/' : '/'}
                title={locale === 'en' ? 'Go back to the home page' : 'Ga terug naar de beginpagina'}
                className="logo logo--overlay-nav"
            />

            <div className="nav__background nav__background--top" />
            <div className="nav__background nav__background--bottom" />

            <ul className="nav__list">
                <li className="list__item">
                    {locale === 'en' ? (
                        <Link title="Our work" to="/en/portfolio/" activeClassName="active" partiallyActive={true}>
                            Work
                        </Link>
                    ) : (
                        <Link title="Ons portfolio" to="/nl/portfolio/" activeClassName="active" partiallyActive={true}>
                            Werk
                        </Link>
                    )}
                </li>
                <li className="list__item">
                    {locale === 'en' ? (
                        <Link title="Our approach" to="/en/approach/" activeClassName="active" partiallyActive={true}>
                            Approach
                        </Link>
                    ) : (
                        <Link
                            title="Onze werkwijze en aanpak"
                            to="/nl/werkwijze/"
                            activeClassName="active"
                            partiallyActive={true}>
                            Aanpak
                        </Link>
                    )}
                </li>
                <li className="list__item list__item--green-circle">
                    {locale === 'en' ? (
                        <a
                            title="Career website (opens in a new tab)"
                            target="_blank"
                            href="https://careers.305.nl/?lang=en"
                            rel="noreferrer">
                            Careers
                        </a>
                    ) : (
                        <a
                            title="Carrière website (opent in een nieuwe tab)"
                            target="_blank"
                            href="https://careers.305.nl/"
                            rel="noreferrer">
                            Carrière
                        </a>
                    )}
                </li>
                <li className="list__item">
                    {locale === 'en' ? (
                        <Link title="Contact information Enschede" to="/en/contact/" activeClassName="active">
                            Contact
                        </Link>
                    ) : (
                        <Link title="Contactgegevens Enschede" to="/nl/contact/" activeClassName="active">
                            Contact
                        </Link>
                    )}
                </li>

                <li className="list__item list__item--locale">
                    <LocaleSwitch
                        locale={locale}
                        targetUrl={translatedPagePath}
                        className="locale-switch--inline locale-switch--large"
                    />
                </li>
            </ul>
        </nav>
    );
};
