import {Link} from 'gatsby';
import React, {FunctionComponent, useLayoutEffect, useState} from 'react';

interface OwnProps {
    targetUrl?: string;
    locale?: 'nl' | 'en';
    navColor?: string;
    className?: string;
}

export const LocaleSwitch: FunctionComponent<OwnProps> = ({targetUrl, locale, navColor, className}) => {
    const url = targetUrl === undefined ? (locale === 'en' ? '/' : '/en/') : targetUrl;

    return (
        <div className={`locale-switch ${className ? className : ''}`}>
            {locale === 'nl' || locale === undefined ? (
                <span style={{color: navColor, borderColor: navColor}}>NL</span>
            ) : (
                <Link style={{color: navColor}} title="Wissel naar het Nederlands" to={url} activeClassName="active">
                    NL
                </Link>
            )}

            {locale === 'en' ? (
                <span style={{color: navColor, borderColor: navColor}}>EN</span>
            ) : (
                <Link style={{color: navColor}} title="Switch to English" to={url} activeClassName="active">
                    EN
                </Link>
            )}
        </div>
    );
};
