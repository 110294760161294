import {Link} from 'gatsby';
import React, {FunctionComponent} from 'react';

import {LocaleSwitch} from './locale_switch';

interface OwnProps {
    locale?: 'nl' | 'en';
    translatedPagePath?: string;
}

export const Footer: FunctionComponent<OwnProps> = ({locale, translatedPagePath}) => {
    return (
        <footer className="footer">
            <Link
                to={locale === 'en' ? '/en/' : '/'}
                title={locale === 'en' ? 'Go back to the home page' : 'Ga terug naar de beginpagina'}
                className="footer__logo"
            />

            <LocaleSwitch
                navColor="#ffffff"
                locale={locale}
                targetUrl={translatedPagePath}
                className="locale-switch--footer"
            />

            <div className="footer__block">
                <div className="footer__column">
                    <nav role="navigation" className="footer__nav">
                        <ul className="nav__list">
                            <li className="list__item">
                                {locale === 'en' ? (
                                    <Link to="/en/portfolio/" title="Our work" activeClassName="active">
                                        Work
                                    </Link>
                                ) : (
                                    <Link to="/nl/portfolio/" title="Ons portfolio" activeClassName="active">
                                        Werk
                                    </Link>
                                )}
                            </li>
                            <li className="list__item">
                                {locale === 'en' ? (
                                    <Link to="/en/approach/" title="Our approach" activeClassName="active">
                                        Approach
                                    </Link>
                                ) : (
                                    <Link to="/nl/werkwijze/" title="Onze aanpak" activeClassName="active">
                                        Aanpak
                                    </Link>
                                )}
                            </li>
                            <li className="list__item list__item--green-circle">
                                {locale === 'en' ? (
                                    <a
                                        title="Career website (opens in a new tab)"
                                        target="_blank"
                                        href="https://careers.305.nl/?lang=en"
                                        rel="noreferrer">
                                        Careers
                                    </a>
                                ) : (
                                    <a
                                        title="Carrière website (opent in een nieuwe tab)"
                                        target="_blank"
                                        href="https://careers.305.nl/"
                                        rel="noreferrer">
                                        Carrière
                                    </a>
                                )}
                            </li>
                            <li className="list__item">
                                {locale === 'en' ? (
                                    <Link
                                        to="/en/contact/"
                                        title="Contact information Enschede"
                                        activeClassName="active">
                                        Contact
                                    </Link>
                                ) : (
                                    <Link to="/nl/contact/" title="Contactgegevens Enschede" activeClassName="active">
                                        Contact
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="footer__column">
                    <nav role="navigation" className="footer__nav">
                        <ul className="nav__list">
                            <li className="list__item">
                                {locale === 'en' ? (
                                    <Link
                                        to="/en/terms-and-conditions/"
                                        title="General terms and conditions"
                                        activeClassName="active">
                                        Terms
                                    </Link>
                                ) : (
                                    <Link to="/nl/voorwaarden/" title="Algemene voorwaarden" activeClassName="active">
                                        Voorwaarden
                                    </Link>
                                )}
                            </li>
                            <li className="list__item">
                                {locale === 'en' ? (
                                    <Link
                                        to="/en/terms-and-conditions/explanation/"
                                        title="Explanation of Approach & Process"
                                        activeClassName="active">
                                        Explanation
                                    </Link>
                                ) : (
                                    <Link
                                        to="/nl/voorwaarden/uitgebreide-toelichting/"
                                        title="Dienstverlening & werkwijze uitbegeid toegelicht"
                                        activeClassName="active">
                                        Toelichting
                                    </Link>
                                )}
                            </li>
                            <li className="list__item">
                                {locale === 'en' ? (
                                    <Link
                                        to="/en/terms-and-conditions/privacy/"
                                        title="Privacy statement, GDPR and processors"
                                        activeClassName="active">
                                        Privacy &amp; GDPR
                                    </Link>
                                ) : (
                                    <Link
                                        to="/nl/voorwaarden/privacy/"
                                        title="Privacybeleid, AVG en Verwerkers"
                                        activeClassName="active">
                                        Privacy &amp; AVG
                                    </Link>
                                )}
                            </li>
                            <li className="list__item">
                                {locale === 'en' ? (
                                    <Link
                                        to="/en/terms-and-conditions/security/"
                                        title="Responsible Disclosure policy"
                                        activeClassName="active">
                                        Security
                                    </Link>
                                ) : (
                                    <Link
                                        to="/nl/voorwaarden/veiligheid/"
                                        title="Ontsluitingsbeleid (Responsible Disclosure)"
                                        activeClassName="active">
                                        Veiligheid
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="footer__column footer__column--right">
                    <Link
                        to={locale === 'en' ? '/en/contact/#enschede' : '/nl/contact/#enschede'}
                        id="enschede"
                        className="footer__location">
                        Enschede
                        {locale === 'en' && <span className="location__country">, NL</span>}
                    </Link>
                    <ul className="footer__list">
                        <li className="list__item">
                            <a
                                title={locale === 'en' ? 'Email our Enschede location' : 'E-mail onze Enschede locatie'}
                                href="mailto:hi@305.nl">
                                hi&thinsp;@305.nl
                            </a>
                        </li>
                        <li className="list__item list__item--divider">
                            <span>Hengelosestraat&nbsp;86</span>
                        </li>
                        <li className="list__item">
                            <span>7514AK Enschede</span>
                        </li>
                        {locale === 'en' ? (
                            <li className="list__item">
                                <span>The Netherlands</span>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
        </footer>
    );
};
