import {FunctionComponent, useLayoutEffect, useState} from 'react';
import React from 'react';
import {useInView} from 'react-intersection-observer';

import {classNames} from '../support/classnames';

interface OwnProps {
    as?: string;
    name?: string;
    className?: string;
    id?: string;
    style?: {};
}

export const WillAnimate: FunctionComponent<OwnProps> = ({
    as,
    name = 'animate',
    id,
    style = {},
    className = '',
    children,
} = {}) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    const [shouldAnimate, setShouldAnimate] = useState(false);
    useLayoutEffect(() => {
        setShouldAnimate(true);
    }, []);

    return React.createElement(
        as || 'article',
        {
            id,
            ref,
            style,
            className: classNames(className, name, {
                [`${name}__will-animate`]: shouldAnimate,
                [`${name}__in-view`]: inView,
            }),
        },
        children,
    );
};
