import {Link} from 'gatsby';
import {FunctionComponent} from 'react';
import React from 'react';

interface OwnProps {
    className?: string;
    url?: string;
    title?: string;
    name: string;
    disabled?: boolean;
    direct?: boolean;
}

export const Button: FunctionComponent<OwnProps> = ({url, title, name, disabled, className, direct}) => {
    const buttonContent = (
        <div className="button__label-wrapper">
            <span className="button__label">{name}</span>
            <span className="button__label--hover">{name}</span>
        </div>
    );

    const buttonClassName = 'button' + (disabled ? ' button--disabled' : '') + (className ? ' ' + className : '');
    if (url) {
        if (direct || (url && url.indexOf(':') > -1 && url.indexOf(':') < url.indexOf('.'))) {
            return (
                <a title={title} className={buttonClassName} target="_blank" href={url} rel="noreferrer">
                    {buttonContent}
                </a>
            );
        } else {
            return (
                <Link to={url} className={buttonClassName}>
                    {buttonContent}
                </Link>
            );
        }
    }

    return (
        <button name={name} className={buttonClassName}>
            {buttonContent}
        </button>
    );
};
