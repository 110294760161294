import {Link} from 'gatsby';
import React, {FunctionComponent, useLayoutEffect, useState} from 'react';

interface OwnProps {
    navColor: string;
    locale?: 'nl' | 'en';
}

export const HeaderNav: FunctionComponent<OwnProps> = ({navColor, locale}) => {
    return (
        <nav role="navigation" className="header__nav">
            <ul className="nav__list">
                <li className="list__item">
                    {locale === 'en' ? (
                        <Link
                            title="Our work"
                            to="/en/portfolio/"
                            activeClassName="active"
                            partiallyActive={true}
                            style={{color: navColor}}>
                            Work
                        </Link>
                    ) : (
                        <Link
                            title="Ons portfolio"
                            to="/nl/portfolio/"
                            activeClassName="active"
                            partiallyActive={true}
                            style={{color: navColor}}>
                            Werk
                        </Link>
                    )}
                </li>
                <li className="list__item">
                    {locale === 'en' ? (
                        <Link
                            title="Our approach"
                            to="/en/approach/"
                            activeClassName="active"
                            partiallyActive={true}
                            style={{color: navColor}}>
                            Approach
                        </Link>
                    ) : (
                        <Link
                            title="Onze werkwijze en aanpak"
                            to="/nl/werkwijze/"
                            activeClassName="active"
                            partiallyActive={true}
                            style={{color: navColor}}>
                            Aanpak
                        </Link>
                    )}
                </li>
                <li className="list__item list__item--green-circle">
                    {locale === 'en' ? (
                        <a
                            style={{color: navColor}}
                            title="Career website (opens in a new tab)"
                            target="_blank"
                            href="https://careers.305.nl/?lang=en"
                            rel="noreferrer">
                            Careers
                        </a>
                    ) : (
                        <a
                            style={{color: navColor}}
                            title="Carrière website (opent in een nieuwe tab)"
                            target="_blank"
                            href="https://careers.305.nl/"
                            rel="noreferrer">
                            Carrière
                        </a>
                    )}
                </li>
                <li className="list__item">
                    {locale === 'en' ? (
                        <Link
                            title="Contact information Enschede"
                            to="/en/contact/"
                            activeClassName="active"
                            style={{color: navColor}}>
                            Contact
                        </Link>
                    ) : (
                        <Link
                            title="Contactgegevens Enschede"
                            to="/nl/contact/"
                            activeClassName="active"
                            style={{color: navColor}}>
                            Contact
                        </Link>
                    )}
                </li>
            </ul>
        </nav>
    );
};
