import {graphql, Link} from 'gatsby';
import React, {FunctionComponent, useContext, useLayoutEffect, useState} from 'react';

import {Footer} from './footer';
import {HeaderNav} from './header_nav';
import {LocaleSwitch} from './locale_switch';
import {OverlayNav} from './overlay_nav';
import {SiteMetadata} from './site_metadata';

interface OwnProps {
    navColor?: string;
    title: string;
    description?: string;
    ogImage?: string;
    locale?: 'nl' | 'en';
    translatedPagePath?: string;
}

function setElementClass(element: Element, className: string, set: boolean) {
    if (set) {
        element.classList.add(className);
    } else {
        element.classList.remove(className);
    }
}

export const HamburgerContext = React.createContext({
    isHamburgerOpen: false,
    setHamburgerOpen: (val: boolean) => {
        console.warn('Hamburger not initialized yet');
    },
});
export const Layout: FunctionComponent<OwnProps> = ({
    title,
    description,
    ogImage,
    children,
    navColor,
    locale,
    translatedPagePath,
}) => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    useLayoutEffect(() => {
        setElementClass(document.body, 'navigation-open', hamburgerOpen);
    }, [hamburgerOpen]);

    return (
        <HamburgerContext.Provider value={{isHamburgerOpen: hamburgerOpen, setHamburgerOpen}}>
            <LayoutContent
                title={title}
                description={description}
                ogImage={ogImage}
                navColor={navColor}
                locale={locale}
                translatedPagePath={translatedPagePath}>
                {children}
            </LayoutContent>
        </HamburgerContext.Provider>
    );
};

const LayoutContent: FunctionComponent<OwnProps> = ({
    title,
    description,
    ogImage,
    children,
    navColor,
    locale,
    translatedPagePath,
}) => {
    const {isHamburgerOpen: hamburgerOpen, setHamburgerOpen} = useContext(HamburgerContext);
    return (
        <>
            <SiteMetadata title={title} description={description} ogImage={ogImage} locale={locale} />
            <Link
                to={locale === 'en' ? '/en/' : '/'}
                title={locale === 'en' ? 'Go back to the home page' : 'Ga terug naar de beginpagina'}
                className="logo"
            />
            <a
                rel="noopener"
                title={locale === 'en' ? 'Open or close menu' : 'Open of sluit menu'}
                className={`hamburger ${hamburgerOpen ? 'hamburger--open' : ''}`}
                onClick={() => {
                    setHamburgerOpen(!hamburgerOpen);
                }}>
                <span />
                <span />
                <span />
            </a>

            <header className="header">
                <LocaleSwitch locale={locale} targetUrl={translatedPagePath} className="locale-switch--fixed" />
                <HeaderNav navColor={navColor || '#292f2f'} locale={locale} />
            </header>

            <OverlayNav locale={locale} translatedPagePath={translatedPagePath} />

            <main>{children}</main>

            <Footer locale={locale} translatedPagePath={translatedPagePath} />
        </>
    );
};

export const avatarImage = graphql`
    fragment avatarImage on File {
        childImageSharp {
            fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;

export const fluid800Width = graphql`
    fragment fluid800Width on File {
        childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;

export const fluid1200Width = graphql`
    fragment fluid1200Width on File {
        childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;

export const fluid1600Width = graphql`
    fragment fluid1600Width on File {
        childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;

export const fullscreenHero = graphql`
    fragment fullscreenHero on File {
        childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
`;
export const inlineAvatarImage = graphql`
    fragment inlineAvatarImage on File {
        childImageSharp {
            fixed(width: 39, height: 39) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
`;

export const logoImage = graphql`
    fragment logoImage on File {
        childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
`;
