import {graphql, StaticQuery, withPrefix} from 'gatsby';
import React, {FunctionComponent} from 'react';
import Helmet from 'react-helmet';

interface OwnProps {
    title: string;
    description?: string;
    ogImage?: string;
    locale?: 'en' | 'nl';
}

export const SiteMetadata: FunctionComponent<OwnProps> = ({title, description, ogImage, locale}) => {
    return (
        <StaticQuery
            query={graphql`
                query siteMetadata {
                    site {
                        siteMetadata {
                            defaultTitle
                            defaultDescription
                            keywords
                            siteUrl
                        }
                    }
                }
            `}
            render={({
                site: {
                    siteMetadata: {defaultTitle, defaultDescription, keywords, siteUrl},
                },
            }) => (
                <Helmet
                    base={{href: siteUrl}}
                    title={`Label305 – ${title || defaultTitle}`}
                    meta={[
                        {name: 'title', content: `Label305 – ${title || defaultTitle}`},
                        {name: 'description', content: description || defaultDescription},
                        {name: 'keywords', content: keywords},
                        {name: 'application-name', content: 'Label305'},
                        {name: 'apple-mobile-web-app-title', content: 'Label305'},

                        {name: 'msapplication-TileColor', content: '#01e1dd'},
                        {name: 'msapplication-TileImage', content: withPrefix('/images/icons/mstile-144x144.png')},
                        {name: 'msapplication-config', content: withPrefix('browserconfig.xml')},
                        {name: 'theme-color', content: '#ffffff'},

                        {name: 'twitter:card', content: 'summary_large_image'},
                        {name: 'twitter:site', content: '@Label305'},
                        {name: 'twitter:title', content: title || defaultTitle},
                        {
                            name: 'twitter:description',
                            content: description || defaultDescription,
                        },
                        {
                            name: 'twitter:image',
                            content: `${siteUrl}/images/og/${ogImage || 'homepage.png'}`,
                        },

                        {
                            property: 'og:image',
                            content: `${siteUrl}/images/og/${ogImage || 'homepage.png'}`,
                        },
                        {property: 'og:image:width', content: '1200'},
                        {property: 'og:image:height', content: '630'},
                        {property: 'og:title', content: title || defaultTitle},
                        {
                            property: 'og:description',
                            content: description || defaultDescription,
                        },
                    ]}
                    link={[
                        {
                            rel: 'apple-touch-icon',
                            sizes: '180x180',
                            href: withPrefix('/images/icons/apple-touch-icon.png'),
                        },

                        {
                            rel: 'icon',
                            type: 'image/png',
                            sizes: '16x16',
                            href: withPrefix('/images/icons/favicon-16x16.png'),
                        },
                        {
                            rel: 'icon',
                            type: 'image/png',
                            sizes: '32x32',
                            href: withPrefix('/images/icons/favicon-32x32.png'),
                        },
                        {
                            rel: 'icon',
                            type: 'image/png',
                            sizes: '192x192',
                            href: withPrefix('/images/icons/android-chrome-192x192.png'),
                        },
                        {
                            rel: 'icon',
                            type: 'image/png',
                            sizes: '194x194',
                            href: withPrefix('/images/icons/favicon-194x194.png'),
                        },

                        {rel: 'manifest', href: withPrefix('/site.webmanifest')},
                        {rel: 'mask-icon', color: '#01e1dd', href: withPrefix('/images/icons/safari-pinned-tab.svg')},
                        {rel: 'shortcut icon', href: withPrefix('/favicon.ico')},
                    ]}>
                    <html prefix="og: http://ogp.me/ns#" lang={locale === 'en' ? 'en' : 'nl'} />
                </Helmet>
            )}
        />
    );
};
