import Img from 'gatsby-image';
import {FunctionComponent} from 'react';
import React from 'react';

import {Button} from './button';
import {WillAnimate} from './content_animate';

interface OwnProps {
    image: any;
    locale?: 'nl' | 'en';
}

export const Cta: FunctionComponent<OwnProps> = ({image, locale}) => {
    if (locale === 'en') {
        return renderEnglishCta(image);
    } else {
        return renderDefaultCta(image);
    }
};

function renderDefaultCta(image: any) {
    return (
        <WillAnimate as="article" className="content animate">
            <div className="container">
                <div className="content__display">
                    <h2>
                        Ook samen <br /> software <br /> ontwik&shy;kelen?
                    </h2>
                </div>
                <div className="content__copy">
                    <p className="p-lead">
                        Creëren van producten doen we samen met jou als opdracht&shy;gever. Heb jij een interessant
                        plan? Neem contact op met{' '}
                        <span className="inline-avatar">
                            <Img Tag="span" alt="Olav" fixed={image} />
                            <span>Olav</span>
                        </span>{' '}
                        voor een verkennend gesprek.
                    </p>
                </div>
                <div className="content__buttons content__buttons--50">
                    <Button url="/nl/contact/" name="Neem contact met ons op" />
                </div>
            </div>
        </WillAnimate>
    );
}

function renderEnglishCta(image: any) {
    return (
        <WillAnimate as="article" className="content animate">
            <div className="container">
                <div className="content__display">
                    <h2>
                        Develop <br /> software <br /> products <br /> with us?
                    </h2>
                </div>
                <div className="content__copy">
                    <p className="p-lead">
                        Creating products is something we do together with you as our client. If you have an interesting
                        idea then get in touch with{' '}
                        <span className="inline-avatar">
                            <Img Tag="span" alt="Olav" fixed={image} />
                            <span>Olav</span>
                        </span>{' '}
                        to discuss your plans.
                    </p>
                </div>
                <div className="content__buttons content__buttons--50">
                    <Button url="/en/contact/" name="Contact us" />
                </div>
            </div>
        </WillAnimate>
    );
}
